import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        userInfo: localStorage["user"] ? JSON.parse(localStorage["user"]) : {},
        token: localStorage["token"] ? localStorage["token"] : '',
        hasSearch:true,
        pageName:'首页',
        cartNum:0,
    },
    mutations: {
        setUserInfo: function(state, payload) {
            console.log(payload)
            state.userInfo = payload;
            state.token = payload.token
        },
        clearUser: function(state, payload) {
            state.userInfo = {}
        },
        sethasSearch:function(state, payload){
            state.hasSearch = payload.hasSearch;
            state.pageName = payload.pageName
        },
        //购物车数量
        setCartNum: function(state, payload) {
            console.log(payload,'cartNum')
            state.cartNum = payload;
        },
    },
    actions: {},
    modules: {},
});