export default {
    data() {
        return {}
    },
    created() {

    },
    methods: {
        messageInfo: function(message) {
            this.$message(message);
        },
        successMessage: function(message) {
            this.$message({
                message: message,
                type: 'success'
            });
        }

    }
}