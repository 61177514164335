export default{
    data(){
        return{
            arrDown:require('@/assets/image/comment_icon_down.png'),
            arrRight:require('@/assets/empty.png'),
            emptyImg:require('@/assets/empty.png'),
        }
    },
     onLoad() {

     },
     methods:{

     }
}