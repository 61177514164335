export default {
    data() {
        return {}
    },
    created() {

    },
    methods: {
        navigation: function(url, query = {}) {
            console.log('url',url)
            this.$router.push({
                path: url,
                query: query
            })
        },
        redirect: function(url, query = {}) {
            this.$router.replace({
                path: url,
                query: query
            })
        },
        back: function(num = -1) {
            this.$router.go(num)
        },
        close: function() {
            let obj = {
                model: false,
            }
            this.$emit("listenValue", obj)
        },
        stopEvent: function() {

        },
        navigationOpen: function(url, query = {}) {
            console.log(this.$route)


            let routeUrl = this.$router.resolve({
                path: url,
                query: query
            })
            window.open(routeUrl.href, '_blank')

        }
    }
}