<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  mounted() {
    const that = this
    window.onresize = () => {
      return (() => {
        document.documentElement.style.fontSize =
          (document.documentElement.clientWidth < 1200 ? 1200 / 1920 : document.documentElement.clientWidth / 1920) * 100 + "px"; //750的比例1px=0.01rem
      })()
    },
      //美洽
      (function (a, b, c, d, e, j, s) {
        a[d] = a[d] || function () {
          (a[d].a = a[d].a || []).push(arguments)
        };
        j = b.createElement(c),
          s = b.getElementsByTagName(c)[0];
        j.async = true;
        j.charset = 'UTF-8';
        j.src = 'https://static.meiqia.com/widget/loader.js';
        s.parentNode.insertBefore(j, s);
      })(window, document, 'script', '_MEIQIA');
    _MEIQIA('entId', '2e23c9f890c7f2134e73650dc5545d45');
    _MEIQIA('withoutBtn');


  },
  watch: {
    $route() {
      //跳转页面
      var os = (function () {
        var ua = navigator.userAgent,
          isWindowsPhone = /(?:Windows Phone)/.test(ua),
          isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone,
          isAndroid = /(?:Android)/.test(ua),
          isFireFox = /(?:Firefox)/.test(ua),
          isChrome = /(?:Chrome|CriOS)/.test(ua),
          isTablet =
            /(?:iPad|PlayBook)/.test(ua) ||
            (isAndroid && !/(?:Mobile)/.test(ua)) ||
            (isFireFox && /(?:Tablet)/.test(ua)),
          isPhone = /(?:iPhone)/.test(ua) && !isTablet,
          isPc = !isPhone && !isAndroid && !isSymbian;
        return {
          isTablet: isTablet,
          isPhone: isPhone,
          isAndroid: isAndroid,
          isPc: isPc
        };
      })();
      console.log(this.$routers, 'routers', this.$router);

      console.log('路由--------------', this.$route);
      if (this.$route.path != '/uni-cabinet') {
        if (os.isAndroid || os.isPhone) {
          console.log("当前设备-手机");
          if (process.env.NODE_ENV === "development") {
            // alert("开发环境");
          } else {
            window.location.href = "http://modulardesign.cn/h5/";

          }
        } else if (os.isTablet) {
          console.log("当前设备-平板");
          window.location.href = "http://modulardesign.cn/h5/";
        } else if (os.isPc) {
          console.log("当前设备-电脑");
        }
      }


    },
  },

}
</script>
<!-- <style scoped>


</style>  -->

<style lang="scss">
#app {
  font-size: 14px
}


.banner-img {
  background: #eee
}

@import "@/assets/css/base.scss";</style>