import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [{
		path: "/",
		name: "base",
		component: () => import("../views/base/index.vue"),
		children: [{
				path: "/",
				name: "index",
				meta: {
					name: "首页",
					hasSearch: true,
					nav: true,
				},
				component: () => import("../views/index/index.vue"),
				children: [],
			},
			{
				path: "/made-cabinet",
				name: "made-cabinet",
				meta: {
					name: "定制柜子",
					nav: true,
				},
				component: () => import("../views/made-cabinet/index.vue"),
			},
			{
				path: "/uni-cabinet",
				name: "uni-cabinet",
				meta: {
					name: "定制柜子2",
				},
				component: () => import("../views/uni-cabinet/index.vue"),
			},
			{
				path: "/product-classify",
				name: "product-classify",
				meta: {
					name: "产品分类",
					nav: true,
					hasSearch: true,
				},
				component: () => import("../views/product-classify/index.vue"),
			},
			{
				path: "/scene-classify",
				name: "scene-classify",
				meta: {
					name: "场景分类",
					nav: true,
					hasSearch: true,
				},
				// component: () => import("../views/scene-classify/index.vue"),
				component: () => import("../views/product-classify/index.vue"),
				
			},
			{
				path: "/company-info",
				name: "company-info",
				meta: {
					name: "公司信息",
					nav: true,
					hasSearch: true,
				},
				component: () => import("../views/company-info/index.vue"),
			},
			{
				path: "/car",
				name: "car",
				meta: {
					name: "购物车",
					nav: true,
					hasSearch: true,
				},
				component: () => import("../views/car/index.vue"),
			},
			{
				path: "/my-order",
				name: "my-order",
				meta: {
					name: "我的订单",
					nav: true,
					hasSearch: false,
				},
				component: () => import("../views/my-order/index.vue"),
			},
			{
				path: "/see-more",
				name: "see-more",
				meta: {
					name: "查看更多",
					nav: false,
					hasSearch: true,
				},
				component: () => import("../views/see-more/index.vue"),
			},
			{
				path: "/refund-service",
				name: "refund-service",
				meta: {
					name: "退款售后",
					nav: false,
					hasSearch: false,
				},
				component: () => import("../views/refund-service/index.vue"),
			},
			{
				path: "/order-detail",
				name: "order-detail",
				meta: {
					name: "订单详情",
					nav: false,
					hasSearch: false,
				},
				component: () => import("../views/order-detail/index.vue"),
			},
			{
				path: "/post-sale",
				name: "post-sale",
				meta: {
					name: "申请售后",
					nav: false,
					hasSearch: false,
				},
				component: () => import("../views/post-sale/index.vue"),
			},
			{
				path: "/appraise",
				name: "appraise",
				meta: {
					name: "待评价-评价",
					nav: false,
					hasSearch: false,
				},
				component: () => import("../views/appraise/index.vue"),
			},
			{
				path: "/service-detail",
				name: "service-detail",
				meta: {
					name: "售后状态详情",
					nav: false,
					hasSearch: false,
				},
				component: () => import("../views/service-detail/index.vue"),
			},
			{
				path: "/address",
				name: "address",
				meta: {
					name: "收货地址",
					nav: false,
					hasSearch: false,
				},
				component: () => import("../views/address/index.vue"),
			},
			{
				path: "/personal-data",
				name: "personal-data",
				meta: {
					name: "个人资料",
					nav: false,
					hasSearch: false,
				},
				component: () => import("../views/personal-data/index.vue"),
			},
			{
				path: "/goods-collects",
				name: "goods-collects",
				meta: {
					name: "商品收藏",
					nav: false,
					hasSearch: false,
				},
				component: () => import("../views/goods-collects/index.vue"),
			},
			{
				path: "/browse-footsteps",
				name: "browse-footsteps",
				meta: {
					name: "浏览足迹",
					nav: false,
					hasSearch: false,
				},
				component: () => import("../views/browse-footsteps/index.vue"),
			},
			{
				path: "/like",
				name: "like",
				meta: {
					name: "猜你喜欢",
					nav: false,
					hasSearch: false,
				},
				component: () => import("../views/like/index.vue"),
			},
			{
				path: "/my-comments",
				name: "my-comments",
				meta: {
					name: "我的评价",
					nav: false,
					hasSearch: false,
				},
				component: () => import("../views/my-comments/index.vue"),
			},
			{
				path: "/system-settings",
				name: "system-settings",
				meta: {
					name: "系统设置",
					nav: false,
					hasSearch: false,
				},
				component: () => import("../views/system-settings/index.vue"),
			},
			{
				path: "/scene-detail",
				name: "scene-detail",
				meta: {
					name: "场景分类",
					nav: false,
					hasSearch: true,
				},
				// component: () => import("../views/scene-detail/index.vue"),
				component: () => import("../views/pro-class-detail/index.vue?category=scene"),
			},
			{
				path: "/pro-class-detail",
				name: "pro-class-detail",
				meta: {
					name: "产品分类",
					nav: false,
					hasSearch: true,
				},
				component: () => import("../views/pro-class-detail/index.vue"),
			},
			{
				path: "/login",
				name: "login",
				meta: {
					name: "登录",
					nav: false,
					hasSearch: true,
				},
				component: () => import("../views/login/index.vue"),
			},
			{
				path: "/register",
				name: "register",
				meta: {
					name: "注册",
					nav: false,
					hasSearch: true,
				},
				component: () => import("../views/register/index.vue"),
			},
			{
				path: "/my-bill",
				name: "my-bill",
				meta: {
					name: "表格模板",
					nav: false,
					hasSearch: false,
				},
				component: () => import("../views/my-bill/index.vue"),
			},
			{
				path: "/confirm-order",
				name: "confirm-order",
				meta: {
					name: "确认订单",
					nav: false,
					hasSearch: true,
				},
				component: () => import("../views/confirm-order/index.vue"),
			},
			{
				path: "/order-pay",
				name: "order-pay",
				meta: {
					name: "付款",
					nav: false,
					hasSearch: true,
				},
				component: () => import("../views/order-pay/index.vue"),
			},
			{
				path: "/pay",
				name: "pay",
				meta: {
					name: "付款成功",
					nav: false,
					hasSearch: false,
				},
				component: () => import("../views/pay/index.vue"),
			},
			{
				path: "/pro-detail",
				name: "pro-detail",
				meta: {
					name: "产品详情",
					nav: false,
					hasSearch: false,
				},
				component: () => import("../views/pro-detail/index.vue"),
			},
		],
	},

];

const router = new VueRouter({
	mode: 'history',
	routes,
});

// //全局后置钩子,为了解决点击diy页面后，再点击其他页面的输入框不生效
// router.afterEach((to, from) => {
// 	console.log(to, from,'to, from');
// 	if(to.path != '/made-cabinet' && from.path=='/made-cabinet'){
// 		location.reload()
// 	}
// })

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((err) => err);
};
export default router;

//添加以下代码
